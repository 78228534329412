import style from "./HotspotMenu.module.css"

import { useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

import { useSite } from "../../stores/useSite"
import { useTransit } from "../../stores/useTransit"
import { useCameras } from "../../stores/useCameras"
import { useHotspot } from "../../stores/useHotspot"
import { useModal } from "../../stores/useModal"
import { useSize } from "../../stores/useSize"
import { useWindowSize } from "@uidotdev/usehooks"
import { Icon } from "./Icon"
import { useSettings } from "../../stores/useSettings"
import { getLocaledPath } from "../../utils/localization"

export function HotspotMenu() {
  /*
   * properties
   */
  const [hotspots, setHotspots] = useState([])
  const [activeIndex, setActiveIndex] = useState([0])
  const { showModal } = useModal()

  const size = useWindowSize()

  const getLabel = useSettings((state) => state.getLabel)

  // const { site, level } = useSite()
  // const { transition } = useTransit()
  // const { cameraStatus } = useCameras()
  // const { activeHotspot, setActiveHotspot, unsetActiveHotspot, getHotspots } =
  //   useHotspot()

  const location = useLocation()

  const site = useSite((state) => state.site)
  const level = useSite((state) => state.level)
  const transition = useTransit((state) => state.transition)
  const cameraStatus = useCameras((state) => state.cameraStatus)
  const activeHotspot = useHotspot((state) => state.activeHotspot)
  const setActiveHotspot = useHotspot((state) => state.setActiveHotspot)
  const unsetActiveHotspot = useHotspot((state) => state.unsetActiveHotspot)
  const getHotspots = useHotspot((state) => state.getHotspots)

  const nav = useRef()
  const highlight = useRef()

  /*
   * hooks
   */

  //resize
  useEffect(() => {
    if (hotspots && hotspots.length > 0 && cameraStatus !== "transition") {
      setHighlight(activeIndex)
    }
  }, [size])

  //hide in case the menu wasn't already hidden (when navigation with prev/next buttons in browser)
  useEffect(() => {
    gsap.set(nav.current, { opacity: 0 })
    //reset active hotspot
    unsetActiveHotspot()
  }, [location])

  // hide menu when out transition begins
  useEffect(() => {
    if (transition === "out") {
      hide()
    }
  }, [transition])

  // camera state related logic
  useEffect(() => {
    // console.log('HotspotMenu :: cameraStatus: ' + cameraStatus);
    if (site && site !== "home") {
      if (cameraStatus === "transition") {
        //hide when the camera transitions to a new level
        hide()
      } else {
        //set hotspots when the camera has stopped transitioning
        const spots = getHotspots(site, level)
        // console.log('HotspotMenu :: getting hotspots');
        // console.log('spots:')
        // console.log(spots);
        setHotspots(spots)
      }
    } else {
      setHotspots([])
    }
  }, [cameraStatus])

  // show/setHighlight when hotspots are loaded
  useEffect(() => {
    if (
      hotspots &&
      hotspots.length > 0 &&
      cameraStatus !== "transition" &&
      cameraStatus !== "start"
    ) {
      //show when the hotspots have been changed
      show()
      //const hotspot = hotspots[0];
      // setActiveHotspot(hotspot.id);
      setHighlight(0, false)
    }
  }, [hotspots])

  /*
   * show/hide
   */

  const show = () => {
    // console.log('HotspotMenu :: show');
    gsap.killTweensOf(nav.current)
    gsap.set(nav.current, { y: 0, opacity: 1, scale: 1 })
    gsap.from(nav.current, {
      delay: 0.3,
      y: 30,
      autoAlpha: 0,
      duration: 0.7,
      ease: "power4.out",
    })
  }

  const hide = () => {
    // console.log('HotspotMenu :: hide');
    gsap.killTweensOf(nav.current)
    gsap.to(nav.current, {
      y: 30,
      autoAlpha: 0,
      duration: 0.3,
      ease: "power2.in",
    })
  }

  /*
   * interaction
   */

  // on mouse over
  const overHandler = (overLevel) => {
    setHighlight(overLevel)
  }

  // on mouse out
  const outHandler = () => {
    var index = hotspots.findIndex((hotspot) => hotspot.id === activeHotspot)
    if (index != -1) {
      setHighlight(index)
    } else {
      setHighlight(0)
    }
  }

  // on click
  const clickHandler = (index) => {
    console.log("HotspotMenu :: clickHandler")
    console.log(index, hotspots[index].id)

    showModal()
    setActiveHotspot(hotspots[index].id)
  }

  /*
   * set highlight
   */

  const setHighlight = (index, animate = true) => {
    // console.log('HotspotMenu :: setHighlight');
    // console.log('index: ' + index);

    setActiveIndex(index)

    // console.log('activeIndex: ' + activeIndex);

    const element = nav.current.children[index + 2]

    if (element) {
      const duration = animate ? 0.35 : 0

      const padding = useSize.getState().isMobile ? 12 : 5

      gsap.killTweensOf(highlight.current)
      gsap.to(highlight.current, {
        // x: element.offsetLeft - 5, // padding + border
        // x: element.offsetLeft - 12, // padding + border
        x: element.offsetLeft - padding, // padding + border
        width: element.offsetWidth,
        duration,
        ease: "power4.inOut",
      })
    }
  }

  /*
   * visuals
   */

  return (
    <nav ref={nav} className={style.nav}>
      <div className={style.title}>
        {getLabel("ui-terms", "hotspot").toUpperCase()}
      </div>
      <div ref={highlight} className={style.highlight} />
      {hotspots
        .filter((hotspot) => !hotspot.isNavOnly)
        .map(
          (value, index) =>
            !value.disabled && (
              <button
                key={index}
                className={`${style.button} ${
                  activeIndex === index && style.active
                } ${value.disabled && style.disabled}`}
                onFocus={() => overHandler(index)}
                onMouseOver={() => overHandler(index)}
                onBlur={outHandler}
                onMouseOut={outHandler}
                onClick={() => clickHandler(index)}
              >
                <div className={style.icon}>
                  <Icon
                    svg={value.hotspotType[0]._type}
                    light={activeIndex !== index}
                    size="small"
                  />
                </div>
                {/* {t(`hotspots.${site}.${value.id}`)} */}
                {value.name}
              </button>
            ),
        )}
    </nav>
  )
}
