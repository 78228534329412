import { useSettings } from "../../stores/useSettings"

export default function Localize({ label, tag = "span" }) {
  const settings = useSettings((state) => state.settings)
  let text = ""
  const Tag = tag
  // console.log("localizing:: ", settings)

  const [section, property] = label.split(".")

  if (section && property && settings[section] && settings[section][property]) {
    text = settings[section][property]
  }

  return <Tag>{text}</Tag>
}
