import style from "./Jobs.module.css"

import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, Pagination } from "swiper/modules"
import { useEffect, useMemo, useState } from "react"
import { JobsPanel } from "./JobsPanel"
import { RoundButton } from "../../../components/RoundButton"
import { ContentTitle } from "../shared/ContentTitle"
import { useSettings } from "../../../../stores/useSettings"

export function Jobs({ content }) {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)
  const [swiper, setSwiper] = useState()

  /*
   * hooks
   */

  useEffect(() => {
    // console.log("Jobs :: get content")
    // console.log(content)
  }, [content])

  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <ContentTitle
        // label={t("modal.jobsTitle")}
        // emoticon={t("modal.jobsEmoticon")}

        label={getLabel("workExperienceSprints", "title").toUpperCase()}
        emoticon={getLabel("workExperienceSprints", "emoji", false)}
      />
      <div className={style.wrapper}>
        <Swiper
          onSwiper={setSwiper}
          className={style.swiper}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1000: {
              slidesPerView: Math.min(content.length, 2),
              slidesPerGroup: Math.min(content.length, 2),
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: Math.min(content.length, 3),
              slidesPerGroup: Math.min(content.length, 3),
              spaceBetween: 20,
            },
            1800: {
              slidesPerView: Math.min(content.length, 4),
              slidesPerGroup: Math.min(content.length, 4),
              spaceBetween: 20,
            },
          }}
          modules={[Grid, Pagination]}
        >
          {content.map((value, index) => (
            <SwiperSlide key={index} className={style.swiperSlide}>
              <JobsPanel data={value} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={style.buttonWrapper}>
          <RoundButton
            icon="arrow-back"
            color="minimal"
            onClick={() => {
              swiper.slidePrev()
            }}
          />
          <RoundButton
            icon="arrow-next"
            color="minimal"
            onClick={() => {
              swiper.slideNext()
            }}
          />
        </div>
      </div>
    </div>
  )
}
