import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/grid"

import "./style.css"

import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { StrictMode, Suspense } from "react"

import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"

import { App3D } from "./app3D/App3D"
import { App2D } from "./app2D/App2D"

const history = createBrowserHistory({ window })

const root = ReactDOM.createRoot(document.querySelector("#root"))

root.render(
  <StrictMode>
    <BrowserRouter history={history}>
      {/* ThreeJS WebGL */}
      <Suspense>
        <App3D />
      </Suspense>

      {/* DOM Elements */}
      <App2D />
    </BrowserRouter>
  </StrictMode>,
)

function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  })

  React.useLayoutEffect(() => history.listen(dispatch), [history])

  return (
    <Router
      children={children}
      action={state.action}
      location={state.location}
      navigator={history}
    />
  )
}
