import { create } from "zustand"
import { getSettings, localize } from "../utils/sanity"

export const useSettings = create((set, get) => ({
  loadingSettings: true,
  hasError: false,
  settings: {},
  sections: {
    construction: "Construction",
    defense: "Defense",
    manufacturing: "Manufacturing",
    healthcare: "Healthcare",
    technology: "Information, Technology & Data",
    business: "Business, Finance & Marketing",
  },

  fetchSanitySettings: async (locale) => {
    try {
      const response = await getSettings()
      const localizedResponse = await localize(response, locale)
      // console.log("useSettings :: response")
      // console.log(localizedResponse)
      set({ settings: localizedResponse })
      // console.log({ settings: localizedResponse })
    } catch (err) {
      console.log(err)
      set({ hasError: true })
    } finally {
      set({ loadingSettings: false })
    }
  },

  fetchSettings: async (locale) => {
    try {
      // console.log("fetchSettings :: start")
      const response = await fetch("/data/settings.json")
      const settings = await response.json()
      const localizedResponse = await localize(settings, locale)
      // console.log("--------")
      // console.log(settings)
      // console.log(settings.result)
      // console.log("--------")

      set({ settings: localizedResponse.result })
      // console.log({ settings: localizedResponse })
    } catch (err) {
      console.log(err)
      set({ hasError: true })
    } finally {
      set({ loadingSettings: false })
    }
  },

  getLabel: (section, label) => {
    // console.log("----------------------")
    // console.log("useSettings :: getLabel")
    // console.log("section", section)
    // console.log("label", label)

    const settings = get().settings
    let result = ";"

    Object.entries(settings).forEach(([index, value]) => {
      if (value._type === "settings." + section) {
        if (value[label]) {
          result = value[label]
        }
      }
    })

    // if (
    //   get().settings &&
    //   get().settings[section] &&
    //   get().settings[section][label]
    // ) {
    //   if (get().settings[section][label]) {
    //     return get().settings[section][label]
    //   } else if (!translated) {
    //     return get().settings[section][label]
    //   }
    // }

    if (result) {
      return result
    } else {
      return section + "-" + label
    }
  },
}))
