import { useEffect } from "react"
import { getImage } from "../../../../utils/sanityImages"
import style from "./JobsPanel.module.css"
import Localize from "../../../components/Localize"

export function JobsPanel({ data }) {
  useEffect(() => {
    // console.log("JobsPanel :: data")
    // console.log(data)
  }, [data])

  return (
    <div className={style.container}>
      <div className={style.media}>
        <img className={style.image} src={getImage(data.thumbnail, 500, 500)} />
      </div>
      <div className={style.content}>
        <h3 className={style.title}>{data.name}</h3>
        <p className={style.subtitle}>{data.subtitle}</p>
        {/* <p className={style.location}>{data.location}</p> */}
        {/* <div className={style.tags}>
          {data.tags &&
            data.tags.length > 0 &&
            data.tags.map((value, index) => (
              <div key={index} className={style.tag}>
                {value.toUpperCase()}
              </div>
            ))}
        </div> */}
        <p className={style.body}>{data.body}</p>
        <a className={style.button} href={data.courseLink} target="_blank">
          {/* <Localize label="programmes.cta" /> */}
          {/* {t("modal.programmesCta")} */}
          Start now
        </a>
      </div>
    </div>
  )
}
