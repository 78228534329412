import { Route, Routes } from "react-router-dom"

import { Staging } from "./core/Staging"
import { Lighting } from "./core/Lighting"
import { PostProcessing } from "./core/Postprocessing"

import { Home } from "./sites/Home"
import { Healthcare } from "./sites/Healthcare"
import { Technology } from "./sites/Technology"
import { Defense } from "./sites/Defense"
import { Manufacturing } from "./sites/Manufacturing"
import { Business } from "./sites/Business"
import { Construction } from "./sites/Construction"

import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"

import { CameraManager } from "./core/CameraManager"
import { useHotspot } from "../stores/useHotspot"
import { useEffect } from "react"
import { useSettings } from "../stores/useSettings"
import { useLocalizedPath } from "../utils/localization"
import { PerformanceMonitor, Preload } from "@react-three/drei"
import { Canvas } from "@react-three/fiber"
import { useSize } from "../stores/useSize"

const ktx2Loader = new KTX2Loader().setTranscoderPath("/loader/basis/")
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath("/loader/draco/gltf/")

const loaders = {
  ktx2Loader,
  dracoLoader,
}
export function App3D() {
  const { locale } = useLocalizedPath()
  // const { fetchSanityHotspots, loadingHotspots } = useHotspot()
  // const { fetchSettings, loadingSettings } = useSettings()

  const fetchHotspots = useHotspot((state) => state.fetchHotspots)
  const fetchSettings = useSettings((state) => state.fetchSettings)

  const fetchSanityHotspots = useHotspot((state) => state.fetchSanityHotspots)
  const loadingHotspots = useHotspot((state) => state.loadingHotspots)
  const fetchSanitySettings = useSettings((state) => state.fetchSanitySettings)
  const loadingSettings = useSettings((state) => state.loadingSettings)

  const dpr = useSize((state) => state.dpr)
  const setDpr = useSize((state) => state.setDpr)

  const antialias = useSize((state) => state.antialias)
  const setAntialias = useSize((state) => state.setAntialias)

  useEffect(() => {
    fetchHotspots()
    fetchSettings(locale)
    // fetchSanityHotspots(locale)
    // fetchSanitySettings(locale)
  }, [])

  const performanceChange = (state) => {
    // console.log(state);

    const sum = state.averages.reduce((acc, val) => acc + val, 0)
    const averageFps = state.averages.length ? sum / state.averages.length : 0
    // console.log(averageFps);

    const deviceDpr = Math.min(2, window.devicePixelRatio)

    if (averageFps < 30) {
      if (antialias) {
        // console.log("set antialias to false")
        setAntialias(false)
      } else if (dpr === deviceDpr) {
        // console.log("set dpr to .75")
        setDpr(deviceDpr * 0.75)
      } else if (dpr > deviceDpr * 0.5) {
        // console.log("set dpr to .5")
        setDpr(deviceDpr * 0.5)
      }
    } else if (averageFps > 50) {
      if (dpr < deviceDpr * 0.75) {
        setDpr(deviceDpr * 0.75)
      } else if (dpr < deviceDpr) {
        setDpr(deviceDpr)
      } else if (!antialias) {
        setAntialias(true)
      }
    }
  }

  const ROUTES = [
    {
      path: "healthcare",
      element: Healthcare,
    },
    {
      path: "technology",
      element: Technology,
    },
    {
      path: "defense",
      element: Defense,
    },
    {
      path: "manufacturing",
      element: Manufacturing,
    },
    {
      path: "business",
      element: Business,
    },
    {
      path: "construction",
      element: Construction,
    },
  ]

  return (
    <Canvas
      shadows={false}
      dpr={dpr}
      flat={true}
      gl={{
        // preserveDrawingBuffer: true,
        powerPreference: "high-performance",
        antialias: antialias,
      }}
    >
      {!loadingHotspots && !loadingSettings && (
        <>
          {/* general content and set-up */}
          <Staging />
          <Lighting />
          <CameraManager />

          {/* <PostProcessing /> */}

          <PerformanceMonitor
            ms={250}
            iterations={5}
            onChange={performanceChange}
            bounds={(refreshrate) => (refreshrate > 90 ? [30, 50] : [30, 50])}
          />

          {/* route dependent content */}
          <Routes>
            {/* route when no locale is used*/}
            <Route path="/" element={<Home {...loaders} />} />
            {ROUTES.map((route, index) => {
              const Component = route.element
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<Component {...loaders} />}
                />
              )
            })}
            {/* localized routes */}
            <Route path=":lang">
              <Route path="" element={<Home {...loaders} />} />
              {ROUTES.map((route, index) => {
                const Component = route.element
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Component {...loaders} />}
                  />
                )
              })}
            </Route>
          </Routes>
          <Preload all />
        </>
      )}
    </Canvas>
  )
}
