import { useSize } from "../../stores/useSize"
import style from "./MainMenu.module.css"
import { MainSubMenu } from "./MainSubMenu"
import { LanguageMenu } from "./LanguageMenu"
import { useSettings } from "../../stores/useSettings"
import { useSite } from "../../stores/useSite"

export function MainMenu() {
  /*
   * properties
   */

  // const { isMobile } = useSize()
  const getLabel = useSettings((state) => state.getLabel)
  const isMobile = useSize((state) => state.isMobile)
  const site = useSite((state) => state.site)

  /*
   * visuals
   */
  return (
    <>
      {isMobile ? (
        <>
          <div className={style.mobileNav}>
            {site !== "home" && <div className={style.dummy} />}
            {/* <a href="http://www.springpod.com" target="_blank"> */}
            <img
              className={style.mobileLogo}
              alt="Springpod logo"
              src="/images/Springpod_logo.svg"
            />
            {/* </a> */}

            <div className={style.mobileRight}>
              <LanguageMenu />
              <a
                className={style.button}
                href="https://itsoffbrand.com"
                target="_blank"
              >
                {/* {getLabel("header", "exitMobile")} */}
                by OFF+BRAND
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <nav className={style.desktopNav}>
            {/* <a href="http://www.springpod.com" target="_blank"> */}
            <img
              className={style.desktopLogo}
              alt="Springpod logo"
              src="/images/Springpod_logo.svg"
            />
            {/* </a> */}

            <MainSubMenu />

            <div className={style.desktopRight}>
              <LanguageMenu />

              {/* <a
                className={style.button}
                href="http://www.springpod.com"
                target="_blank"
              >
                {getLabel("header", "exitDesktop")}
              </a> */}
            </div>
          </nav>
          )
        </>
      )}
    </>
  )
}
