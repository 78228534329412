import style from "./LevelMenuMobile.module.css"
import gsap from "gsap"

import { useEffect, useRef, useState } from "react"

// import { useLocation } from 'react-router';
import { useCameras } from "../../../stores/useCameras"
import { useTransit } from "../../../stores/useTransit"
import { useHotspot } from "../../../stores/useHotspot"
import { useSite } from "../../../stores/useSite"
import { useSize } from "../../../stores/useSize"
import { useSettings } from "../../../stores/useSettings"

export function LevelMenuMobile() {
  /*
   * properties
   */

  const [shown, setShown] = useState(false)

  const getLabel = useSettings((state) => state.getLabel)
  // const { isMobile } = useSize();
  // const { site, level, setLevel } = useSite();
  // const { unsetActiveHotspot } = useHotspot();
  // const { transition } = useTransit();
  // const { cameraStatus } = useCameras();
  // const location = useLocation();

  const isMobile = useSize((state) => state.isMobile)
  const site = useSite((state) => state.site)
  const level = useSite((state) => state.level)
  const setLevel = useSite((state) => state.setLevel)
  const unsetActiveHotspot = useHotspot((state) => state.unsetActiveHotspot)
  const transition = useTransit((state) => state.transition)
  const cameraStatus = useCameras((state) => state.cameraStatus)

  const nav = useRef()
  const buttons = useRef()
  const container = useRef()

  const icon = useRef()
  const layer1 = useRef()
  const layer2 = useRef()
  const layer3 = useRef()

  /*
   * hooks
   */

  // hide in case the menu wasn't already hidden (when navigation with prev/next buttons in browser)
  useEffect(() => {
    // console.log('LevelMenuMobile :: hide in case it wasnt already');
    // console.log('container.current');
    // console.log(container.current);

    // console.log('test.current');
    // console.log(test.current);

    // console.log('shown');
    // console.log(shown);

    gsap.set(container.current, { opacity: 0 })
  }, [location])

  useEffect(() => {
    // console.log("icon.current")
    // console.log(icon.current)

    if (icon.current) {
      const layers = Array.from(icon.current.children)
      for (var i = 0; i < 3; i++) {
        gsap.set(layers[i], {
          y: 8 + i * -8,
          rotate: 45,
        })
      }
    }
  }, [icon.current, isMobile])

  // hide in case the menu wasn't already hidden (when navigation with prev/next buttons in browser)
  // useEffect(() => {
  //   // console.log('LevelMenuMobile :: hide in case it wasnt already');
  //   if (nav.current && useTransit.getState().transition === 'pause') {
  //     gsap.set(nav.current, { opacity: 0 });
  //   }
  // }, [nav.current]);

  // // set the XXX when changing level
  // useEffect(() => {
  //   // console.log('OPTION 2');
  //   //TO DO: set correct button
  // }, [level]);

  // transition state related logic
  useEffect(() => {
    // console.log('OPTION 3');
    // console.log('LevelMenuMobile :: change transition');
    // console.log('transition', transition);

    if (transition === "out") {
      // hide menu when the out transition starts
      // console.log('LevelMenuMobile :: HIDE');
      gsap.killTweensOf(container.current)
      gsap.to(container.current, {
        y: 30,
        autoAlpha: 0,
        duration: 0.3,
        ease: "power2.in",
      })
    }
  }, [transition])

  // show menu when the in transition starts
  useEffect(() => {
    // console.log('SET CAMERA STATUS')
    // console.log(cameraStatus)
    if (cameraStatus === "intro" && site !== "home") {
      // console.log('LevelMenuMobile :: SHOW');

      gsap.killTweensOf(container.current)
      gsap.set(container.current, { y: 0, opacity: 1, scale: 1 })
      gsap.from(container.current, {
        delay: 0.3,
        y: 30,
        autoAlpha: 0,
        duration: 0.7,
        ease: "power4.out",
      })

      //TO DO: set correct button
    }
  }, [cameraStatus])

  /*
   * interaction
   */

  // on click
  const clickHandler = (newLevel) => {
    //TO DO: set correct button or go to level

    // console.log('useSite.getState().level');
    // console.log(useSite.getState().level);
    // console.log('newLevel');
    // console.log(newLevel);

    // gsap.set(buttons.current, { y: 30 })

    if (newLevel === useSite.getState().level) {
      setShown(!shown)
      gsap.to(nav.current, { height: 100, duration: 0.4, ease: "sine.inOut" })
      gsap.to(buttons.current, { y: 0, duration: 0.4, ease: "sine.inOut" })

      const layers = Array.from(icon.current.children)
      for (var i = 0; i < 3; i++) {
        gsap.to(layers[i], {
          y: 0 + i * -15,
          rotate: -45,
          duration: 0.8,
          ease: "power2.inOut",
        })
      }
    } else {
      setShown(false)
      unsetActiveHotspot()
      setLevel(newLevel)

      gsap.to(nav.current, { height: 40, duration: 0.4, ease: "power2.inOut" })
      gsap.to(buttons.current, {
        y: (newLevel - 1) * 30,
        duration: 0.4,
        ease: "sine.inOut",
      })

      const layers = Array.from(icon.current.children)
      for (var i = 0; i < 3; i++) {
        gsap.to(layers[i], {
          y: 8 + i * -8,
          rotate: 45,
          duration: 0.6,
          ease: "power2.inOut",
        })
      }
    }
  }

  /*
   * visuals
   */

  return (
    <>
      <div id="LevelMenuMobile" ref={container} className={style.container}>
        {isMobile && site !== "home" && (
          // <nav ref={nav} className={`${style.nav} ${shown ? style.shown : ""}`}>
          <>
            <div ref={icon} className={style.icon}>
              <div ref={layer1} className={style.layer} />
              <div ref={layer2} className={style.layer} />
              <div ref={layer3} className={style.layer} />
            </div>
            <nav ref={nav} className={style.nav}>
              <div ref={buttons} className={style.buttons}>
                {[...Array(3)].map((value, index) => (
                  <button
                    key={index}
                    // className={`${style.button} ${level === 3 - index && style.active} ${
                    //   (shown || level === 3 - index) && style.shown
                    // }`}
                    className={`${style.button} ${level === 3 - index && style.active} ${
                      (shown || level === 3 - index) && style.shown
                    }`}
                    onClick={() => clickHandler(3 - index)}
                  >
                    {getLabel("ui-terms", "level").toUpperCase()} {3 - index}
                  </button>
                ))}
              </div>
            </nav>
          </>
        )}
      </div>
    </>
  )
}
