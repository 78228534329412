import {
  OrbitControls,
  PerspectiveCamera,
  useAnimations,
  useGLTF,
} from "@react-three/drei"
import { useEffect } from "react"
import { useMemo } from "react"
import * as THREE from "three"

// import { ReflectionMesh } from '../components/ReflectionMesh';
import { SiteHotspot } from "../components/SiteHotspot"
import { useHotspot } from "../../stores/useHotspot"
import { useSite } from "../../stores/useSite"
import { useCameras } from "../../stores/useCameras"
import { useDepthOfField } from "../../stores/useDepthOfField"
import { useThree } from "@react-three/fiber"

// const ktx2Loader = new KTX2Loader().setTranscoderPath('loader/basis/');
// const dracoLoader = new DRACOLoader();
// dracoLoader.setDecoderPath('loader/draco/gltf/');

export function Site({
  name,
  cameraScale,
  focusDistance,
  focusLength,
  init,
  cameraOrder,
  url,
  screenTextures,
  ktx2Loader,
  dracoLoader,
}) {
  /*
   * properties
   */

  // const { setSite, setNodes } = useSite()
  // const { setCameras } = useCameras()
  // const { getHotspot } = useHotspot()
  // const { setDepthOfField } = useDepthOfField()

  const setSite = useSite((state) => state.setSite)
  const setNodes = useSite((state) => state.setNodes)
  const setCameras = useCameras((state) => state.setCameras)
  const getHotspot = useHotspot((state) => state.getHotspot)

  const { gl } = useThree()

  const model = useGLTF(url, false, false, (loader) => {
    //KTX2
    loader.setKTX2Loader(ktx2Loader.detectSupport(gl))

    //DRACO
    loader.setDRACOLoader(dracoLoader)
  })
  const animations = useAnimations(model.animations, model.scene)

  // material used for glass surfaces
  const glassMaterial = useMemo(() => {
    const material = new THREE.MeshStandardMaterial({
      color: 0x90d4ff,
      roughness: 0,
      envMapIntensity: 3,
      metalness: 0.5,
    })
    return material
  }, [])

  /*
   * hooks
   */

  // set the main global properties when the current Site is activated
  useEffect(() => {
    setSite(name)
    // setDepthOfField(focusDistance, focusLength)
  }, [])

  // set the model related global properties when the model is loaded
  useEffect(() => {
    // console.log('Site :: useEffect - model');

    init && init(model, animations)

    const cameras = [
      model.cameras[cameraOrder[0]],
      model.cameras[cameraOrder[1]],
      model.cameras[cameraOrder[2]],
      model.cameras[cameraOrder[3]],
    ]

    setCameras(cameras, cameraScale)
    setNodes(model.nodes)
  }, [model])

  // set the model related local properties when the model is loaded
  const children = useMemo(() => {
    // init && init(model);

    // console.log(model)

    const reflections = []
    const hotspots = []

    for (const nodeName in model.nodes) {
      //console.log(nodeName);
      const node = model.nodes[nodeName]

      hotspots
      if (nodeName.toLowerCase().includes("hotspot")) {
        // console.log('node includes "hotspot"', nodeName)
      }
      const data = getHotspot(nodeName, name)
      if (data) {
        // console.log("Site - HOTSPOT FOUND: " + nodeName)
        hotspots.push({ node: node, data: data })
      }

      if (nodeName.toLowerCase().includes("reflective_window")) {
        // console.log('reflective_window');
        node.material = glassMaterial
      }

      if (nodeName.toLowerCase().includes("branding_small")) {
        // console.log(node)
        // const tx = node.material.map
        // tx.needsUpdate = true
        // tx.generateMi
        // node.material.map = tx
        // console.log()
      }

      // screens
      if (nodeName.toLowerCase().includes("screen")) {
        // console.log('node includes "screen"', nodeName);
      }

      if (screenTextures[nodeName]) {
        // console.log("Site - SCREENTEXTURE FOUND: " + nodeName)

        const texture = screenTextures[nodeName]
        texture.wrapT = THREE.RepeatWrapping
        texture.repeat.y = -1

        // console.log(node)
        // console.log(node.material)
        // console.log(texture)
        
        //TEXTURE BUG FIX
        if (node && node.material && texture) {
          node.material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(1, 1, 1),
            map: texture,
          })
        }
      }
    }

    return { reflections, hotspots }
  }, [model])

  /*
   * visuals
   */

  return (
    <>
      <group dispose={null}>
        <primitive object={model.scene} />

        {/* {children.reflections.map((node, index) => (
          <ReflectionMesh key={index} id={index + 1} mesh={node} />
        ))} */}

        {children.hotspots.map(
          (value, index) =>
            value.data.hotspotType &&
            !value.data.disabled &&
            value.data.hotspotType.length > 0 && (
              <SiteHotspot key={index} mesh={value.node} data={value.data} />
            ),
        )}
      </group>

      {/* TEMP */}

      {/* <PerspectiveCamera
        makeDefault
        fov={17}
        near={0.1}
        far={100}
        scale={1}
        // position={[0.28640151023864746, 0.30490005016326904, 3.952479839324951]}
        position={[model.cameras[1].position.x, model.cameras[1].position.y, model.cameras[1].position.z]}
        // rotation={[0.009999999776482582, 0.009999998845160007, 0.009999998845160007]}
        rotation={[model.cameras[1].rotation.x, model.cameras[1].rotation.y, model.cameras[1].rotation.z]}
      /> */}

      {/* <PerspectiveCamera
        makeDefault
        zoom={1}
        focus={10}
        fov={32}
        near={0.1}
        far={10000}
        position={[model.cameras[0].position.x, model.cameras[0].position.y, model.cameras[0].position.z]}
        rotation={[model.cameras[0].rotation.x, model.cameras[0].rotation.y, model.cameras[0].rotation.z]}
      /> */}

      {/* <OrbitControls makeDefault /> */}
    </>
  )
}
